import React, { useEffect, useState, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box, Button, Divider, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Switch } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import * as accountActions from "../../store/actions/account"
import SkeletonLoader from "reUsable/skeleton";
import * as packActions from "../../store/actions/package"
import * as countryActions from "../../store/actions/country"
import ClearIcon from '@mui/icons-material/Clear';
import DataTable from "./table";
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import NotFound from "reUsable/NotFound";
import Checkbox from '@mui/material/Checkbox';
import { columnsCustomer } from "./columns"
import { CSVLink } from 'react-csv';
import CSVButton from "../../reUsable/CSVButton"
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { parseISO } from "date-fns";
import moment from "moment";
import DateBtn from "reUsable/DateBtn";
import SearchInfluencer from "reUsable/SearchInfluencer";
import DownloadIcon from '@mui/icons-material/Download';
import devicename from "reUsable/devicename"
const column = [
    {
        key: "pixel_id",
        label: "PID",
    },
    {
        key: "created_at",
        label: "Created On",
    },
    {
        key: "is_ai_search",
        label: "AI Search",
    },
    {
        key: "name",
        label: "Name",
    },

    {
        key: "status",
        label: "Status",
    },
    {
        key: "device",
        label: "Device",
    },
    {
        key: "device_detail",
        label: "Device Name",
    },
    {
        key: "device_version",
        label: "OS Version",
    },
    {
        key: "version",
        label: "Version",
    },
    {
        key: "email",
        label: "Email",
    },
    {
        key: "phone",
        label: "Phone No.",
    },
    {
        key: "otp_verified",
        label: "OTP",
    },
    {
        key: "email_verified",
        label: "Email Verified",
    },
    {
        key: "instagram_username",
        label: "Instagram Username",
    },
    {
        key: "paypal_email",
        label: "Paypal",
    },
    {
        key: "shipping_addresses",
        label: "Shipping",
    },

];
const countryCodes = [
    { id: '+1', label: 'United States' },
    { id: '+44', label: 'United Kingdom' },
    { id: '+91', label: 'India' },
    { id: '+92', label: 'Pakistan' },
    { id: '+61', label: 'Australia' },
    { id: '+81', label: 'Japan' },
    { id: '+49', label: 'Germany' },
    { id: '+33', label: 'France' },
    { id: '+86', label: 'China' },
    { id: '+55', label: 'Brazil' },
    { id: '+39', label: 'Italy' },
    { id: '+34', label: 'Spain' },
    { id: '+7', label: 'Russia' },
    { id: '+27', label: 'South Africa' },
    { id: '+82', label: 'South Korea' },
    { id: '+966', label: 'Saudi Arabia' },
    { id: '+971', label: 'United Arab Emirates' },
    { id: '+234', label: 'Nigeria' },
    { id: '+20', label: 'Egypt' },
    { id: '+353', label: 'Ireland' },
    { id: '+64', label: 'New Zealand' },
    { id: '+98', label: 'Iran' },
    { id: '+52', label: 'Mexico' },
    { id: '+60', label: 'Malaysia' },
    { id: '+66', label: 'Thailand' },
    { id: '+90', label: 'Turkey' },
    { id: '+62', label: 'Indonesia' },
    { id: '+351', label: 'Portugal' },
    { id: '+31', label: 'Netherlands' },
    { id: '+45', label: 'Denmark' },
    { id: '+46', label: 'Sweden' },
    { id: '+48', label: 'Poland' },
    { id: '+43', label: 'Austria' },
    { id: '+41', label: 'Switzerland' },
    { id: '+30', label: 'Greece' },
    { id: '+359', label: 'Bulgaria' },
    { id: '+36', label: 'Hungary' },
    { id: '+972', label: 'Israel' },
    { id: '+63', label: 'Philippines' },
    { id: '+57', label: 'Colombia' },
    { id: '+505', label: 'Nicaragua' },
    { id: '+94', label: 'Sri Lanka' },
    { id: '+372', label: 'Estonia' },
    { id: '+380', label: 'Ukraine' },
    { id: '+56', label: 'Chile' },
    { id: '+386', label: 'Slovenia' },
    { id: '+420', label: 'Czech Republic' },
    { id: '+32', label: 'Belgium' },
    { id: '+258', label: 'Mozambique' },
    { id: '+233', label: 'Ghana' },
    { id: '+48', label: 'Poland' },
    { id: '+43', label: 'Austria' },
    { id: '+41', label: 'Switzerland' },
    { id: '+84', label: 'Vietnam' },
    { id: '+880', label: 'Bangladesh' },
    { id: '+507', label: 'Panama' },
    { id: '+212', label: 'Morocco' },
    { id: '+591', label: 'Bolivia' },
    { id: '+963', label: 'Syria' },
    { id: '+505', label: 'Nicaragua' },
    { id: '+355', label: 'Albania' },
    { id: '+268', label: 'Eswatini' },
    { id: '+506', label: 'Costa Rica' },
    { id: '+54', label: 'Argentina' },
    { id: '+598', label: 'Uruguay' },
    { id: '+52', label: 'Mexico' },
    { id: '+244', label: 'Angola' }
];



function Accounts({ getAccount, accounts, getPackages, packages, getCountry, countries, getStates, getCity, clearAccounts, getAccountList, accountList, type, getAccountCSV }) {
    const fileName = "customerDetail";
    const history = useHistory()

    const dispatch = useDispatch()
    const [load, setLoad] = useState(true)
    const [loading, setLoading] = useState(false)
    const [tableLoading, setTableLoading] = useState(false)
    const [search, setSearch] = useState(true)
    const [fields, setFields] = useState({
        name: "",
        email: "",
        // package_id: "all",
        status: 1,
        country: "",
        // cycle: "",
        // instagram_username: "",
        is_login_permission: "all",
        state: "",
        city: "",
        show_delete_user: false,
        account_type: "influencer",
        // app: type === "house" ? "all" : true,
        app: "all",
        // shop: "all",
        // featured: "all",
        user_id: '',
        device: 'all',
        orderBy: -1,
        sort: "pixel_id",
        version: "",
        phone: '',
        is_house_influencer: type === "house" ? true : false,
        is_ai_search: 'all',
        is_talent: 'all',
        email_verified: 'all',
        instagram_status: 'all',
        shipping_addresses: 'all',
        otp_verified: "all",
        paypal_email: 'all',
        pixel_id: "",
        phoneCode: []

    })
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])

    const [countryName, setCountryName] = useState("")
    const [stateName, setStateName] = useState("")
    const [submit, setSubmit] = useState(false)
    const [name, setName] = useState("")
    const [nameLoad, setNameLoad] = useState(false)
    const [header, setHeader] = useState([])
    const [data, setData] = useState([])

    const [value, setValue] = useState([
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
    ]);
    const [date, setDate] = useState(false);
    const [timezone, setTimezone] = useState(false) //false: local, true: UTC
    const [count, setCount] = useState(0)
    const [csvLoad, setCSVLoad] = useState(false)
    const [csvData, setCSVData] = useState([])
    useEffect(() => {
        getCountry()
        // getPackages()
        onSearch()
        return () => {
            dispatch({
                type: "clearMinimize"
            })
        }
    }, [])
    useEffect(() => {
        setHeader(columnsCustomer.map(m => ({ label: m.headerName, key: m.field })))
    }, [])


    function onSearch() {
        setSubmit(true)
        // fields.account_type && fields.country
        setLoading(true)
        const upFields = { ...fields }
        if (fields.status == 2) {
            upFields.is_active = false
        }
        else if (fields.status == 1) {
            upFields.is_active = true
        } else {
            upFields.is_active = undefined;
        }
        setTableLoading(true)
        getAccount(1, upFields, value)
            .then((res) => {
                setCount(res?.count)
                setDate(false)
                setSearch(true)
                setLoading(false)
                setTableLoading(false)
                // getData(res.count)
            })
    }
    useEffect(() => {
        if (date) {
            onSearch()
        }
    }, [date])

    useEffect(() => {
        setCSVLoad(true)

        if (count > 0) {
            const upFields = { ...fields }
            if (fields.status == 2) {
                upFields.is_active = false
            }
            else if (fields.status == 1) {
                upFields.is_active = true
            } else {
                upFields.is_active = undefined;
            }
            getAccountCSV(upFields, value, count).then((res) => {
                console.log("res", res)
                setCSVData(res?.message?.map(item => {
                    let deviceDetail = ''
                    if (item?.device_brand) {
                        if (item?.device === 'ios') {
                            deviceDetail = `${item?.device_brand} ${devicename(item?.device_name)}`
                        } else {
                            deviceDetail = `${item?.device_brand} ${item?.device_name}`
                        }
                    }
                    return {
                        ...item,
                        created_at: moment(item.created_at).format('MM-DD-YYYY'),
                        is_ai_search: item?.is_ai_search ? "On" : "Off",
                        status: item?.deleted ? "Deleted" : item?.is_active ? "Active" : "Deactive",
                        device: item?.device ? item?.device.charAt(0).toUpperCase() + item?.device.slice(1) : "Web",
                        device_detail: deviceDetail,
                        otp_verified: item?.otp_verified ? "Verified" : "Not Verified",
                        email_verified: item?.email_verified ? "Verified" : "Not Verified",
                        instagram_username: item?.instagram_username ? item?.instagram_username : '',
                        paypal_email: item?.paypal_email ? "Connected" : "Not Connected",
                        shipping_addresses: item?.shipping_addresses?.length > 0 ? "Connected" : "Not Connected",
                    }
                }))
                setCSVLoad(false)
            })
        } else {
            setCSVData([])
            setCSVLoad(false)
        }
    }, [count])

    function renderClear() {
        return (
            <Col xs={12} md={12} lg={6} xl={3} className="filter-clear-col mb-20">
                <Button
                    className="btn-width btn-gen-margin laptop ml-0"
                    disableElevation
                    size="medium"
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={onSearch}
                >
                    Search
                </Button>
                <Button
                    className="btn-width btn-gen-margin grey-btn-outline laptop"
                    variant="outlined"
                    sx={{ color: 'gray' }}
                    startIcon={<ClearIcon />}
                    onClick={() => {
                        setSearch(false)
                        clearAccounts()
                        setNameLoad(true)
                        getAccountList('', false, fields.account_type, type).then(() => setNameLoad(false))
                        const payload = {
                            name: "",
                            email: "",
                            // plan: "",
                            // package_id: "all",
                            status: 1,
                            country: "",
                            // cycle: "",
                            // instagram_username: "",
                            is_login_permission: "all",
                            city: "",
                            state: "",
                            account_type: "influencer",
                            show_delete_user: false,
                            // app: type === "house" ? "all" : true,
                            app: "all",
                            // shop: "all",
                            // featured: "all",
                            user_id: '',
                            device: 'all',
                            orderBy: -1,
                            sort: "pixel_id",
                            version: "",
                            phone: '',
                            is_house_influencer: type === "house" ? true : false,
                            is_ai_search: 'all',
                            is_talent: 'all',
                            email_verified: 'all',
                            instagram_status: 'all',
                            shipping_addresses: 'all',
                            otp_verified: "all",
                            paypal_email: 'all',
                            pixel_id: '',
                            phoneCode: []
                        }

                        setFields(payload)
                        setCountryName("")
                        setStateName("")
                        setSubmit(false)
                        setName("")
                        ///
                        setLoading(true)
                        const upFields = { ...payload }
                        if (payload.status == 2) {
                            upFields.is_active = false
                        }
                        else if (payload.status == 1) {
                            upFields.is_active = true
                        } else {
                            upFields.is_active = undefined;
                        }
                        setTableLoading(true)
                        setValue([moment().format("YYYY-MM-DD"),
                        moment().format("YYYY-MM-DD"),])
                        getAccount(1, upFields, [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"),])
                            .then((res) => {
                                setDate(false)
                                setSearch(true)
                                setLoading(false)
                                setTableLoading(false)
                                // getData(res.count)
                            })
                    }}
                >
                    Clear
                </Button>
            </Col>
        )
    }


    function renderPackages() {
        return packages.map((item) => {
            return <MenuItem key={item.package_id} value={item.package_id}>{item.package_name}</MenuItem>
        })
    }

    useEffect(() => {
        setNameLoad(true)
        getAccountList('', false, fields.account_type, type).then(() => setNameLoad(false))
    }, [])

    function debounce(cb, delay = 500) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }
    const handleChange = (val) => {
        getAccountList(val, false, fields.account_type, type).then(() => setNameLoad(false))
    };
    const optimizedFn = useCallback(debounce(handleChange), []);
    function renderField() {
        return columnsCustomer.map((m, i) => {
            if (m.field !== "sNo") return <MenuItem key={i} value={m.field} >{m.headerName}</MenuItem>
        })
    }
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    return (
        <React.Fragment>
            <div className='container-fluid mt-3 mb-3 pl-0'>

                <Row className='pl-15'>
                    <Col xs className="mb-3 d-flex align-items-center">
                        <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                            {type === "house" ? "House" : "Users"}
                        </Typography>
                    </Col>
                    <Col xs className="mb-3 d-flex justify-content-end align-items-center">
                        {
                            type !== 'house' &&
                            <>
                                <div style={{}}>
                                    {
                                        csvLoad ?
                                            <Button
                                                disabled
                                                className='btn-width'
                                                startIcon={<DownloadIcon />}
                                                disableElevation
                                                variant="contained">
                                                Loading...
                                            </Button>
                                            :
                                            <CSVLink
                                                headers={column}
                                                data={csvData}
                                                asyncOnClick={true}
                                                className="btn-width h-100 custom-btn"
                                                onClick={() => { }}
                                                filename={'users'}
                                            >
                                                <CSVButton load={csvLoad} />
                                            </CSVLink>
                                    }

                                </div>
                                {/* <div style={{ marginRight: "14px", marginBottom: "6px", fontsize: "14px", fontWeight: "500", position: "relative" }}>Timezone: </div>
                                <div>

                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            className="d-flex align-items-center"
                                        >
                                            
                                            <FormControlLabel onClick={() => setTimezone(false)} checked={timezone === false} control={<Radio size="small" />} label="Local" />
                                            <FormControlLabel onClick={() => setTimezone(true)} checked={timezone} control={<Radio size="small" />} label="UTC" />
                                        </RadioGroup>
                                    </FormControl>
                                </div> */}

                            </>


                            // <Stack direction="row" spacing={1} alignItems="center">
                            //     <Typography>Local</Typography>
                            //     <Switch checked={timezone} onChange={(e, checked) => setTimezone(checked)} />
                            //     <Typography>UTC</Typography>
                            // </Stack>
                        }
                    </Col>
                    {
                        type === "house" &&
                        <Col xs className="mb-3 text-right">
                            <Button
                                onClick={() => {
                                    history.push('/house/add')
                                }}
                                className='btn-width'
                                startIcon={<AddIcon />}
                                disableElevation
                                variant="contained">
                                Add House
                            </Button>
                        </Col>
                    }
                </Row>
                <DateBtn value={value} setValue={(data) => {
                    setValue(data)
                }} setDate={setDate} />
                <Row className="account-main-row pl-15">
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <div className="dateBox">
                                <DatePicker
                                    minDate={new Date(2023, 0, 1)}
                                    slotProps={{
                                        textField: {
                                            helperText: "YYYY / MM / DD",
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="Start Date"
                                    value={parseISO(value[0])}
                                    onChange={(newValue) => {
                                        setValue([moment(newValue).format("YYYY-MM-DD"), value[1]]);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                                <Box sx={{ mx: 2 }}> to </Box>
                                <DatePicker
                                    minDate={new Date(2023, 0, 1)}
                                    slotProps={{
                                        textField: {
                                            helperText: "YYYY / MM / DD",
                                        },
                                    }}
                                    allowSameDateSelection
                                    sx={{ color: "black" }}
                                    label="End Date"
                                    value={parseISO(value[1])}
                                    onChange={(newValue) => {
                                        setValue([value[0], moment(newValue).format("YYYY-MM-DD")]);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="field-style"
                                            size="small"
                                            style={{ backgroundColor: "white", width: "50%" }}
                                            {...params}
                                        />
                                    )}
                                />
                            </div>
                        </LocalizationProvider>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >AI Search</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.is_ai_search}
                                label="AI Search"
                                onChange={(e) => {
                                    setFields({ ...fields, is_ai_search: e.target.value })
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={true}>On</MenuItem>
                                <MenuItem value={false}>Off</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Instagram Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.instagram_status}
                                label="Instagram Status"
                                onChange={(e) => {
                                    setFields({ ...fields, instagram_status: e.target.value })
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={true}>Connected</MenuItem>
                                <MenuItem value={false}>Not Connected</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    {/* <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl
                            // error={(submit && !fields.account_type)} 
                            className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Account Type</InputLabel>
                            <Select
                                disabled
                                labelId="demo-simple-select-label1"
                                id="demo-simple-select1"
                                value={"influencer"}
                                label="Account Type"
                                onChange={(e) => {
                                    setFields({ ...fields, account_type: e.target.value })
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={"influencer"}>Influencer</MenuItem>
                                <MenuItem value={"brand"}>Brand</MenuItem>
                                <MenuItem value={"customer"}>Shopper</MenuItem>
                            </Select>
                        </FormControl>
                    </Col> */}
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            value={fields.pixel_id}
                            onChange={e => setFields({ ...fields, pixel_id: e.target.value })}
                            id="outlined-basic"
                            label="PID"
                            variant="outlined"
                            className="field-style"
                        />
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <SearchInfluencer setter={(value) => setFields({ ...fields, user_id: value })} brand={fields.user_id} type={type}
                            username={fields.name}
                            editName={(val) => {
                                setFields({ ...fields, name: val })
                            }}
                        />
                        {/* <Autocomplete
                            variant="outlined"
                            className="field-style"
                            size="small"
                            id="disable-clearable"
                            disableClearable
                            loading={nameLoad}
                            options={accountList?.message ? accountList?.message.map(opt => ({ ...opt, label: `${opt.pixel_id} - ${opt.name}` })) : []}
                            onChange={(e, data) => {
                                setFields({ ...fields, user_id: data._id })
                                // setFields({ ...fields, name: data.label })
                                setName(data.label)

                            }}
                            value={name}
                            renderInput={(params) => <TextField {...params} label="Name" onChange={(e) => {
                                if (e.target.value !== '') {
                                    setName(e.target.value)
                                    setFields({ ...fields, name: e.target.value })
                                    setNameLoad(true)
                                    optimizedFn(e.target.value)
                                } else {
                                    setName('')
                                    setFields({ ...fields, name: '', user_id: '' })
                                    setNameLoad(true)
                                    optimizedFn(e.target.value)
                                }

                            }} />}
                        /> */}
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            value={fields.email}
                            onChange={e => setFields({ ...fields, email: e.target.value })}
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            className="field-style"
                        />
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <Autocomplete
                            id="combo-box-demo"
                            limitTags={1}
                            disablePortal
                            getOptionDisabled={(option) => fields.phoneCode.find(f => f.id === option.id) ? true : false}
                            value={fields.phoneCode}
                            options={countryCodes.map(item => ({ ...item, label: `${item.label} (${item.id})` }))}
                            multiple
                            onChange={(e, data) => {
                                setFields({ ...fields, phoneCode: data })
                            }}
                            size="small"
                            className="field-style"
                            renderInput={(params) => (
                                <TextField
                                    {...params} label="Country Code" />
                            )}
                        />
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            value={fields.phone}
                            onChange={e => setFields({ ...fields, phone: e.target.value })}
                            id="outlined-basic"
                            label="Phone"
                            variant="outlined"
                            className="field-style"
                        />
                    </Col>
                    {/* <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            value={countryName}
                            options={countries.map(it => ({ ...it, label: it.name }))}
                            onChange={(e, data) => {
                                if (data?.code1) {
                                    setCountryName(data.name)
                                    setFields({ ...fields, country: data.code1 })
                                    getStates(data.code1)
                                        .then((res) => {
                                            setStates(res?.message)
                                        })
                                } else {
                                    setFields({ ...fields, country: "", state: "", city: "" })
                                    setCountryName("")
                                    setStateName("")
                                    setStates([])
                                }
                            }}
                            size="small"
                            className="field-style"
                            renderInput={(params) => (
                                <TextField
                                    // error={(submit && !countryName)} 
                                    {...params} label="Country" />
                            )}
                        />
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <Autocomplete
                            disabled={!fields.country}
                            disablePortal
                            value={stateName}
                            id="combo-box-demo"
                            options={states.map(it => ({ ...it, label: it.name }))}
                            onChange={(e, data) => {
                                if (data?.isoCode) {
                                    setStateName(data.name)
                                    setFields({ ...fields, state: data.isoCode })
                                    getCity({
                                        country_code: fields.country,
                                        state_code: data.isoCode
                                    }).then(res => {
                                        setCities(res?.message)
                                    })
                                } else {
                                    setFields({ ...fields, state: "", city: "" })
                                    setStateName("")
                                    setCities([])
                                }
                            }}
                            size="small"
                            className="field-style"
                            renderInput={(params) => <TextField {...params} label="State" />}
                        />
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <Autocomplete
                            disabled={(!fields.country || !fields.state)}
                            disablePortal
                            value={fields.city}
                            id="combo-box-demo"
                            options={cities.map(it => ({ ...it, label: it.name }))}
                            onChange={(e, data) => {
                                if (data?.name) {
                                    setFields({ ...fields, city: data.name })
                                } else {
                                    setFields({ ...fields, city: "" })
                                }
                            }}
                            size="small"
                            className="field-style"
                            renderInput={(params) => <TextField {...params} label="City" />}
                        />
                    </Col> */}
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Status</InputLabel>
                            <Select

                                sx={[{ color: "black" }]}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.status === "active" ? 1 : fields.status}
                                label="Status"
                                onChange={(e) => {
                                    if (e.target.value == 3) {
                                        setFields({ ...fields, status: e.target.value, show_delete_user: true })
                                    } else {
                                        setFields({ ...fields, status: e.target.value, show_delete_user: false })
                                    }
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={1}>Active</MenuItem>
                                <MenuItem value={2}>Deactive</MenuItem>
                                <MenuItem value={3}>Delete</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Public</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.app}
                                label="Public"
                                onChange={(e) => {
                                    setFields({ ...fields, app: e.target.value })
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={true}>On</MenuItem>
                                <MenuItem value={false}>Off</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Device</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.device}
                                label="Device"
                                onChange={(e) => {
                                    setFields({ ...fields, device: e.target.value })
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={"android"}>Android</MenuItem>
                                <MenuItem value={"ios"}>IOS</MenuItem>
                                <MenuItem value={"web"}>Web</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <TextField
                            size="small"
                            value={fields.version}
                            onChange={e => setFields({ ...fields, version: e.target.value })}
                            id="outlined-basic"
                            label="Version"
                            variant="outlined"
                            className="field-style"
                        />
                    </Col>
                    {
                        type === undefined &&
                        <>
                            <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                                <FormControl className="field-style" size="small">
                                    <InputLabel
                                        id="demo-simple-select-label"
                                    >Source By</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={fields.is_talent}
                                        label="Source By"
                                        onChange={(e) => {
                                            setFields({ ...fields, is_talent: e.target.value })
                                        }}
                                    >
                                        <MenuItem value={"all"}>All</MenuItem>
                                        <MenuItem value={false}>ORME</MenuItem>
                                        <MenuItem value={true}>Agency</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                                <FormControl className="field-style" size="small">
                                    <InputLabel
                                        id="demo-simple-select-label"
                                    >Email Verified</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={fields.email_verified}
                                        label="Email Verified"
                                        onChange={(e) => {
                                            setFields({ ...fields, email_verified: e.target.value })
                                        }}
                                    >
                                        <MenuItem value={"all"}>All</MenuItem>
                                        <MenuItem value={"empty"}>Empty</MenuItem>
                                        <MenuItem value={true}>Verified</MenuItem>
                                        <MenuItem value={false}>Not Verified</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                                <FormControl className="field-style" size="small">
                                    <InputLabel
                                        id="demo-simple-select-label"
                                    >Paypal</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={fields.paypal_email}
                                        label="Paypal"
                                        onChange={(e) => {
                                            setFields({ ...fields, paypal_email: e.target.value })
                                        }}
                                    >
                                        <MenuItem value={"all"}>All</MenuItem>
                                        <MenuItem value={true}>Connected</MenuItem>
                                        <MenuItem value={false}>Not Connected</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                                <FormControl className="field-style" size="small">
                                    <InputLabel
                                        id="demo-simple-select-label"
                                    >Shipping</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={fields.shipping_addresses}
                                        label="Shipping"
                                        onChange={(e) => {
                                            setFields({ ...fields, shipping_addresses: e.target.value })
                                        }}
                                    >
                                        <MenuItem value={"all"}>All</MenuItem>
                                        <MenuItem value={true}>Connected</MenuItem>
                                        <MenuItem value={false}>Not Connected</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                                <FormControl className="field-style" size="small">
                                    <InputLabel
                                        id="demo-simple-select-label"
                                    >OTP</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={fields.otp_verified}
                                        label="OTP"
                                        onChange={(e) => {
                                            setFields({ ...fields, otp_verified: e.target.value })
                                        }}
                                    >
                                        <MenuItem value={"all"}>All</MenuItem>
                                        <MenuItem value={true}>Verified</MenuItem>
                                        <MenuItem value={false}>Not Verified</MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>

                        </>
                    }
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className='field-style' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Field</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="Field"
                                labelId="field"
                                id="field"
                                value={fields.sort}
                                label="Field"
                                onChange={(e) => setFields({ ...fields, sort: e.target.value })}
                            >
                                {/* {renderField()} */}
                                <MenuItem value={'pixel_id'} >PID</MenuItem>
                                <MenuItem value={'created_at'} >Date</MenuItem>
                                <MenuItem value={'name'} >Name</MenuItem>
                                <MenuItem value={'device'} >Device</MenuItem>
                                <MenuItem value={'version'} >Version</MenuItem>
                                <MenuItem value={'email'} >Email</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={12} lg={6} xl={3} className="mb-20">
                        <FormControl className=' field-style' size="small">
                            <InputLabel
                                id="demo-simple-select-label1"
                            >Order By</InputLabel>
                            <Select
                                sx={{ color: "black" }}
                                placeholder="order by"
                                labelId="orderBy"
                                id="orderBy"
                                value={fields.orderBy}
                                label="Order By"
                                onChange={(e) => setFields({ ...fields, orderBy: e.target.value })}
                            >
                                <MenuItem value={1}>Ascending</MenuItem>
                                <MenuItem value={-1}>Descending</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>



                    {renderClear()}
                </Row>
                <Divider className="ml-15" />

                <DataTable
                    fields={fields}
                    date={date}
                    timezone={timezone}
                    userTypes={type}
                    user="customer"
                    tableLoading={tableLoading}
                    nowGet={(page) => {
                        setTableLoading(true)
                        getAccount(page, fields, value).then(() => setTableLoading(false))
                    }}
                    accounts={accounts}
                    search={search}
                    loadTable={loading}
                />
            </div>
        </React.Fragment>
    );
}

const styles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}

const mapStateToProps = ({ accounts, packages, countries, accountList }) => {
    return { accounts, packages, countries, accountList };
};

export default connect(mapStateToProps, { ...accountActions, ...packActions, ...countryActions })(Accounts);
