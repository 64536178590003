import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as discoverActions from "../../store/actions/discover.actions"
import SaveModal from "../../reUsable/SaveModal"
import { Col, Row } from 'react-bootstrap'
import { Box, Button, Divider, InputAdornment, TextField, Typography } from '@mui/material'
import moment from 'moment'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SaveIcon from '@mui/icons-material/Save';
import { toast } from 'react-toastify'

export const Section = ({ getDiscover, updateDiscover, type }) => {
    console.log("111")
    const [load, setLoad] = useState(true)
    const [modal, setModal] = useState(false)
    const [fields, setFields] = useState({
        sort_by: '',
        date_range: '',
    })
    useEffect(() => { getData() }, [])

    function submit() {
        setLoad(true)
        let data = {
            key: type,
            discover_filter: { ...fields }
        }
        updateDiscover(data).then((res) => {
            if (res.success) {
                toast.success(res?.message)
                setLoad(false)
            } else {
                toast.error(res?.message)
                setLoad(false)
            }
            getData()
        })
    }

    function getData() {
        getDiscover({ type }).then((res) => {
            if (res?.success) {
                const data = res?.data?.[0]
                setFields({
                    sort_by: data?.discover_filter?.sort_by,
                    date_range: data?.discover_filter?.date_range,
                })
            } else {
                setFields({
                    sort_by: '',
                    date_range: '',
                })
            }
        }).finally(() => {
            setModal(false)
            setLoad(false)
        })
    }
    return (
        <Col xl={5} lg={6} md={12}>
            <SaveModal
                visible={modal}
                title={`Are you sure you want to save these changes?`}
                close={() => setModal(false)}
                type="status"
                call={() => submit()}
            />
            <div className='infoSection new mb-3' style={{ background: "#FFF" }} >
                <Box className="addons-cart-title d-flex align-items-center">
                    <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                        Popular
                    </Typography>
                </Box>
                <Divider className='mb-20' />
                <Row>
                    <Col xs={12}>
                        <div className='w-100'>
                            <TextField
                                size="small"
                                value={fields.date_range}
                                onChange={e => {
                                    let val = e.target.value;
                                    if (val > 0) {
                                        setFields({ ...fields, date_range: Number(val) })
                                    }
                                }}
                                id="outlined-basic"
                                type='number'
                                label="Date Range"
                                InputProps={{
                                    inputProps: {
                                        min: 1
                                    },
                                    endAdornment: <InputAdornment position="end">days</InputAdornment>,

                                }}
                                variant="outlined"
                                className="field-style"
                            />
                            <div style={{ textAlign: 'right', fontSize: "0.8rem", color: "gray", fontWeight: "500" }} >
                                {`${moment().subtract(fields.date_range, 'days').format("D MMM YY")} - ${moment().format("D MMM YY")}`}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div style={{ fontWeight: "500", color: "#052977" }}>Sort By</div>
                        <Divider className='mb-20' />
                    </Col>
                    <Col xs={12}>
                        <div style={{ display: "flex" }}>
                            <div className='w-100'>
                                <TextField
                                    sx={{ input: { cursor: 'pointer' } }}
                                    onClick={() => setFields({ ...fields, sort_by: "created_date" })}
                                    focused={fields.sort_by === "created_date"}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Date "}
                                />
                            </div>
                            <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                {fields.sort_by === "created_date" ? <CheckCircleIcon color='primary' /> : <CheckCircleOutlineIcon style={{ color: "gray" }} />}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div style={{ display: "flex" }}>
                            <div className='w-100'>
                                <TextField
                                    sx={{ input: { cursor: 'pointer' } }}
                                    onClick={() => setFields({ ...fields, sort_by: "likes" })}
                                    focused={fields.sort_by === "likes"}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15 "
                                    value={"Likes"}
                                />
                            </div>
                            <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                {fields.sort_by === "likes" ? <CheckCircleIcon color='primary' /> : <CheckCircleOutlineIcon style={{ color: "gray" }} />}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div style={{ display: "flex" }}>
                            <div className='w-100'>
                                <TextField
                                    sx={{ input: { cursor: 'pointer' } }}
                                    onClick={() => setFields({ ...fields, sort_by: "views" })}
                                    focused={fields.sort_by === "views"}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15 "
                                    value={"Views"}
                                />
                            </div>
                            <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                {fields.sort_by === "views" ? <CheckCircleIcon color='primary' /> : <CheckCircleOutlineIcon style={{ color: "gray" }} />}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div style={{ display: "flex" }}>
                            <div className='w-100'>
                                <TextField
                                    sx={{ input: { cursor: 'pointer' } }}
                                    onClick={() => setFields({ ...fields, sort_by: "shares" })}
                                    focused={fields.sort_by === "shares"}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15 "
                                    value={"Shares"}
                                />
                            </div>
                            <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                {fields.sort_by === "shares" ? <CheckCircleIcon color='primary' /> : <CheckCircleOutlineIcon style={{ color: "gray" }} />}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div style={{ display: "flex" }}>
                            <div className='w-100'>
                                <TextField
                                    sx={{ input: { cursor: 'pointer' } }}
                                    onClick={() => setFields({ ...fields, sort_by: "comments" })}
                                    focused={fields.sort_by === "comments"}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15 "
                                    value={"Comments"}
                                />
                            </div>
                            <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                {fields.sort_by === "comments" ? <CheckCircleIcon color='primary' /> : <CheckCircleOutlineIcon style={{ color: "gray" }} />}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div style={{ display: "flex" }}>
                            <div className='w-100'>
                                <TextField
                                    sx={{ input: { cursor: 'pointer' } }}
                                    onClick={() => setFields({ ...fields, sort_by: "topdiscount" })}
                                    focused={fields.sort_by === "topdiscount"}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Top Discount "}
                                />
                            </div>
                            <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                {fields.sort_by === "topdiscount" ? <CheckCircleIcon color='primary' /> : <CheckCircleOutlineIcon style={{ color: "gray" }} />}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div style={{ display: "flex" }}>
                            <div className='w-100'>
                                <TextField
                                    sx={{ input: { cursor: 'pointer' } }}
                                    onClick={() => setFields({ ...fields, sort_by: "add_to_store" })}
                                    focused={fields.sort_by === "add_to_store"}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15 "
                                    value={"Add to Store"}
                                />
                            </div>
                            <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                {fields.sort_by === "add_to_store" ? <CheckCircleIcon color='primary' /> : <CheckCircleOutlineIcon style={{ color: "gray" }} />}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div style={{ display: "flex" }}>
                            <div className='w-100'>
                                <TextField
                                    sx={{ input: { cursor: 'pointer' } }}
                                    onClick={() => setFields({ ...fields, sort_by: "saves " })}
                                    focused={fields.sort_by === "saves "}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15 "
                                    value={"Favorites "}
                                />
                            </div>
                            <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                {fields.sort_by === "saves" ? <CheckCircleIcon color='primary' /> : <CheckCircleOutlineIcon style={{ color: "gray" }} />}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div style={{ display: "flex" }}>
                            <div className='w-100'>
                                <TextField
                                    sx={{ input: { cursor: 'pointer' } }}
                                    onClick={() => setFields({ ...fields, sort_by: "influencerfee" })}
                                    focused={fields.sort_by === "influencerfee"}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Influencer Fee"}
                                />
                            </div>
                            <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                {fields.sort_by === "influencerfee" ? <CheckCircleIcon color='primary' /> : <CheckCircleOutlineIcon style={{ color: "gray" }} />}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div style={{ display: "flex" }}>
                            <div className='w-100'>
                                <TextField
                                    sx={{ input: { cursor: 'pointer' } }}
                                    onClick={() => setFields({ ...fields, sort_by: "referralfee" })}
                                    focused={fields.sort_by === "referralfee"}
                                    label=" "
                                    inputProps={{ readOnly: true, }}
                                    InputLabelProps={{ shrink: false }}
                                    size="small"
                                    variant="outlined"
                                    className="field-style mb-15"
                                    value={"Referral Fee"}
                                />
                            </div>
                            <div style={{ marginTop: "6px", marginLeft: "5px" }}>
                                {fields.sort_by === "referralfee" ? <CheckCircleIcon color='primary' /> : <CheckCircleOutlineIcon style={{ color: "gray" }} />}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className='mb-2'>
                        <Button
                            className="btn-width btn-gen-margin  mr-0 h-100 ml-0"
                            disableElevation
                            size="medium"
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={() => setModal(true)}
                            disabled={load}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
            </div>
        </Col>
    )
}

const mapStateToProps = ({ }) => ({})
export default connect(mapStateToProps, discoverActions)(Section)