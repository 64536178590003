import { CLEAR_CREATORS, GET_CREATORS } from "../../actions/type";

const initialState = {};

export default function creators(state = initialState, action) {
    switch (action.type) {
        case GET_CREATORS:
            return action.payload;
        case CLEAR_CREATORS:
            return action.payload;
        default:
            return state
    }
}