import React, { useState, useEffect, useMemo } from 'react'
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap"
import { Button, Divider, InputAdornment, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import * as feesAction from "../../../store/actions/feeSetting"
import { toast } from 'react-toastify';

function FeesSettings({ fees, getFeesSetting, updateFees }) {
    const [fields, setFields] = useState({
        content_creator_fee: 0,
        referral_fee: 0,
        shopper_cashback: 0,
        normal_user_referral_fee: 0,
    })
    useEffect(() => { getData() }, [])
    useMemo(() => {
        if (fees) { setter() }
    }, [fees])

    const handleSave = () => {
        updateFees(fields).then((res) => {
            if (res.success) {
                toast.success(`${res?.message}`)
            } else {
                toast.error(`${res?.message}`)
            }
        }).finally(() => getData())
    }
    function setter() {
        setFields({
            content_creator_fee: fees ? fees?.content_creator_fee : 0,
            referral_fee: fees ? fees?.referral_fee : 0,
            shopper_cashback: fees ? fees?.shopper_cashback : 0,
            normal_user_referral_fee: fees ? fees?.normal_user_referral_fee : 0,
        })
    }
    function getData() {
        getFeesSetting()
    }

    return (
        <div className="container-fluid mb-3">
            <Row className='pt-3'>
                <Col xs className="mb-3 d-flex align-items-center">
                    <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                        Fee Structure
                    </Typography>
                </Col>
            </Row>
            <Divider className="mb-15" />
            <Row className="d-flex justify-content-between">
                <Col xl={4} lg={6} md={12}>
                    <div className='infoSection new mb-3' style={{ background: "#FFF" }} >
                        <Box className="addons-cart-title d-flex align-items-center">
                            <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                            Creator/Influencer
                            </Typography>
                        </Box>
                        <Divider className='mb-20' />
                        {/* <TextField
                            size="small"
                            type='number'
                            value={fields.shopper_cashback}
                            onChange={e => {
                                let val = e.target.value;
                                if (val >= 0 || val <= 100) {
                                    setFields({ ...fields, shopper_cashback: val })
                                }
                            }}
                            id="outlined-basic"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                inputProps: {
                                    min: 0,
                                    max: 100
                                }
                            }}
                            label="Shopper Cashback"
                            variant="outlined"
                            className="field-style mb-15"
                        /> */}
                        <TextField
                            size="small"
                            type='number'
                            value={fields.content_creator_fee}
                            onChange={e => {
                                let val = e.target.value;
                                if (val >= 0 || val <= 100) {
                                    setFields({ ...fields, content_creator_fee: val })
                                }
                            }}
                            id="outlined-basic"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                inputProps: {
                                    min: 0,
                                    max: 100
                                }
                            }}
                            label="Creator Fee"
                            variant="outlined"
                            className="field-style mb-15"
                        />
                         <TextField
                            size="small"
                            type='number'
                            value={fields.referral_fee}
                            onChange={e => {
                                let val = e.target.value;
                                if (val >= 0 || val <= 100) {
                                    setFields({ ...fields, referral_fee: val })
                                }
                            }}
                            id="outlined-basic"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                inputProps: {
                                    min: 0,
                                    max: 100
                                }
                            }}
                            label="Referral Fee"
                            variant="outlined"
                            className="field-style mb-15"
                        />
                        <Divider className='mb-20 mt-2' />
                          <Box className="addons-cart-title d-flex align-items-center">
                            <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                               User
                            </Typography>
                        </Box>
                        <Divider className='mb-20' />
                        <TextField
                            size="small"
                            type='number'
                            value={fields.normal_user_referral_fee}
                            onChange={e => {
                                let val = e.target.value;
                                if (val >= 0 || val <= 100) {
                                    setFields({ ...fields, normal_user_referral_fee: val })
                                }
                            }}
                            id="outlined-basic"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                inputProps: {
                                    min: 0,
                                    max: 100
                                }
                            }}
                            label="Referral Fee"
                            variant="outlined"
                            className="field-style mb-15"
                        />
                        <Button
                            className="btn-width btn-gen-margin grey-btn-outline h-100 mb-2"
                            variant="outlined"
                            sx={{ color: 'gray', marginLeft: 0 }}
                            disableElevation
                            size="medium"
                            startIcon={<RotateLeftIcon />}
                            onClick={setter}
                        >
                            Reset
                        </Button>
                        <Button
                            className="btn-width btn-gen-margin ml-0 h-100  mb-2"
                            disableElevation
                            size="medium"
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={() => handleSave()}
                        >
                            Save
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = ({ fees }) => {
    return { fees };
};

export default connect(mapStateToProps, feesAction)(FeesSettings);