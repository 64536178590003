import React, { useEffect, useMemo, useState } from 'react'
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { Row, Col } from 'react-bootstrap';
import * as giftSectionAction from "../../../store/actions/giftSection"
import * as accountActions from "../../../store/actions/account"
import moment from "moment";
import SaveIcon from '@mui/icons-material/Save';

import { connect } from 'react-redux'
import Card from '@mui/material/Card';
import { toast } from 'react-toastify';


function GiftSetting({ currentUser, deleted, accountDetail, updateGifting, getDetails }) {
    const [load, setLoad] = useState(false)
    const [submits, setSubmits] = useState(false);
    const [value, setValue] = useState([moment().format('YYYY-MM-DD'), moment().add(1, 'y').format('YYYY-MM-DD')])
    const [date, setDate] = useState(null)
    const [fields, setFields] = useState({
        gifting_product_limit: 0,
        gifting_request_limt: 0,
    })

    useEffect(() => {
        getDetails(currentUser, deleted).then((res) => {
            console.log(res?.gifting_product_limit)
            console.log(res?.gifting_request_limt)
            setFields({
                gifting_product_limit: res?.gifting_product_limit,
                gifting_request_limt: res?.gifting_request_limt,
            })
        })

    }, [])

    const submit = () => {
        setSubmits(true)
        let data = {
            user_id: currentUser,
            gifting_product_limit: fields.gifting_product_limit,
            gifting_request_limt: fields.gifting_request_limt,
        }
        console.log(data);
        updateGifting(data).then((res) => {
            console.log(res)
            getDetails(currentUser, deleted)
            setSubmits(false)
            toast.success('Successfully updated', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true,
                progress: undefined,
            })
        }).catch(err => console.log(err));
    }

    return (
        <div className='saleContainer'>
            <Row className='align-items-center'>
                <Col xl={5} lg={6}>
                    <Card variant="outlined" className='users-card-main' style={{ minHeight: 'initial' }} sx={{ minWidth: 275 }}>
                        <Typography className='title-bar-hd-main mb-1' color="primary" variant="h5" gutterBottom component="div">
                            Gifting Setup
                        </Typography>
                        <Divider className='mb-4' />

                        <TextField
                            value={fields.gifting_product_limit}
                            onChange={(e) => { setFields({ ...fields, gifting_product_limit: e.target.value }) }}
                            id="limit"
                            label="Gifting Product Limit"
                            type="number"
                            size="small"
                            className="field-style mb-4"
                            variant="outlined" />
                        <TextField
                            value={fields.gifting_request_limt}
                            onChange={(e) => { setFields({ ...fields, gifting_request_limt: e.target.value }) }}
                            id="request"
                            label="Gifting Request Limit"
                            type="number"
                            size="small"
                            className="field-style mb-4"
                            variant="outlined" />

                        <Button
                            className="btn-width btn-gen-margin ml-0 h-100 webBTN"
                            disableElevation
                            size="medium"
                            variant="contained"
                            startIcon={<SaveIcon />}
                            onClick={() => submit()}
                        >
                            Submit
                        </Button>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
const styles = {
    input: {
        marginBottom: 20
    }
}

function mapStateToProps({ accountDetail }) {
    return { accountDetail }
}
export default connect(mapStateToProps, { ...giftSectionAction, ...accountActions })(GiftSetting)