import konnect from "./axios"

export const getShopKeywords = () => async (dispatch) => {
    try {
        const res = await konnect.post(`/v1/admin/appSetting/getShopKeywords`)
        return res?.data
    } catch (err) {
        return err?.response
    }
}
export const updateShopKeywords = (keywords) => async (dispatch) => {
    try {
        keywords = keywords.map(row=> row.toLowerCase());
        const res = await konnect.post(`/v1/admin/appSetting/createAndUpdateShopKeywords`, { keywords })
        return res?.data
    } catch (err) {
        return err?.response
    }
}