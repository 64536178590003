import React from 'react';
import { BrowserRouter as Router, Route, useRouteMatch } from "react-router-dom"
import Addons from './components/Addons';
import EmailStatus from './components/EmailStatus';
import Package from './components/Package';
import UserStatus from './components/UserStatus';
import ViewInfo from './components/ViewInfo';
import Transactions from './components/Transactions';
import Subscriptions from './components/Subscription';
import Sales from "./components/Sales"
import UserSales from "./components/UserSales"
import Spending from "./components/Spending"
import Balance from "./components/Balance"
import Deposit from './components/Deposit';
import Contract from "./components/Contract"
import Purchase from "./components/Purchase"
import Cashback from './components/Cashback';
import Withdrawal from './components/Withdrawal';
import Refund from "./components/Refund"
import Website from './components/Website';
import ReferralSales from './components/ReferralSales';
import Orders from './components/Orders';
import TotalSales from './components/TotalSales/TotalSales';
import MarketFee from './components/MarketFee';
import Purchases from './components/Purchases';
import ReferralStats from './components/ReferralStats';
import Earnings from './components/Earnings';
import ReviewSales from './components/ReviewSales';
import ReviewSaleInfluencer from './components/ReviewSaleInfluencer';
import CategorySetup from './components/CategorySetup';
import Agreement from './components/Agreement';
import PaymentMethods from './components/PaymentMethods';
import Shipping from './components/Shipping';
import Discounts from './components/Discounts';
import Balances from './components/Balances';
import ViewInfoAgency from './components/ViewInfoAgency';
import AgreementAgency from './components/AgreementAgency';
import TalentList from './components/TalentList/TalentList';
import TotalSalesAgency from '../salesReportsAgency/TotalSalesAgency';
import AgencyEarnings from './components/AgencyEarnings';
import SearchTags from './components/SearchTags';
import GiftSettings from "./components/giftSettings"

export default function Conditional({ currentUser, active, deleted, currentCustomerId, nowGet, userType, close }) {
    const { path } = useRouteMatch()
    if (active == 1) {
        if (userType === "agency") {
            return <ViewInfoAgency currentUser={currentUser} nowGet={nowGet} close={close} />
        } else {
            return <ViewInfo deleted={deleted} currentUser={currentUser} nowGet={nowGet} userType={userType} close={close} />
        }
    }
    else if (active == 2) {
        return <Package deleted={deleted} currentUser={currentUser} userType={userType} nowGet={nowGet} />
    }
    else if (active == 3) {
        return <TotalSales currentUser={currentUser} userType={userType} currentCustomerId={currentCustomerId} />
    }
    else if (active == 4) {
        return <ReferralSales deleted={deleted} currentUser={currentUser} userType={userType} />
    }
    // else if (active == 5) {
    //     return <MarketFee currentUser={currentUser} userType={userType} currentCustomerId={currentCustomerId} />
    // }
    else if (active == 6) {
        return <Contract currentUser={currentUser} deleted={deleted} />
    }
    else if (active == 7) {
        return <Website currentUser={currentUser} userType={userType} currentCustomerId={currentCustomerId} />
    }
    else if (active == 8) {
        return <Purchases currentUser={currentUser} />
    }
    else if (active == 9) {
        return <ReferralStats currentUser={currentUser} user_type={userType} />
    }
    else if (active == 10) {
        if (userType === "agency") {
            return <AgencyEarnings currentUser={currentUser} />
        } else {
            return <Earnings currentUser={currentUser} />
        }
    }
    else if (active == 12) {
        return <ReviewSales deleted={deleted} currentUser={currentUser} userType={userType} />
    }
    else if (active == 13) {
        return <ReviewSaleInfluencer deleted={deleted} currentUser={currentUser} user_type={userType} />
    }
    else if (active == 14) {
        return <CategorySetup deleted={deleted} currentUser={currentUser} user_type={userType} />
    }
    else if (active == 11) {
        return <Withdrawal currentUser={currentUser} userType={userType} />
    }
    else if (active == 15) {
        if (userType === "agency") {
            return <AgreementAgency currentUser={currentUser} nowGet={nowGet} deleted={deleted} />
        } else {
            return <Agreement currentUser={currentUser} nowGet={nowGet} deleted={deleted} />
        }
        // <Withdrawal currentUser={currentUser} userType={userType} />
    }
    else if (active == 16) {
        return <PaymentMethods userType={userType} currentUser={currentUser} nowGet={nowGet} deleted={deleted} />
    }
    else if (active == 17) {
        return <Shipping currentUser={currentUser} deleted={deleted} userType={userType} />
    }
    else if (active == 18) {
        return <Discounts currentUser={currentUser} deleted={deleted} userType={userType} />
    }
    else if (active == 19) {
        return <Balances currentUser={currentUser} deleted={deleted} userType={userType} />
    }
    else if (active == 20) {
        return <TalentList currentUser={currentUser} userType={userType} />
    }
    else if (active == 21) {
        return <TotalSalesAgency currentUser={currentUser} />
    }
    else if (active == 22) {
        return <SearchTags currentUser={currentUser} deleted={deleted} userType={userType} />
    }
    else if (active == 23) {
        return <GiftSettings currentUser={currentUser} deleted={deleted} userType={userType} />
    }
}
