import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ReportIcon from '@mui/icons-material/Report';
import { Col, Row } from 'react-bootstrap';
import { Divider, TextField } from '@mui/material';

const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    // p: 4,
    // p: 
};

export default function EditModal({ close, visible, call, setter, value }) {
    return (
        <div>
            <Modal
                open={visible}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {/* <Box className="deleteModal w-20 ">
                    <Typography className='fw-normal mb-0' color="primary" variant="h6" gutterBottom component="div">
                        Variant Feed
                    </Typography>

                </Box> */}
                {/* <Divider className='mb-20' /> */}
                <Box sx={style} className="deleteModal w-30" style={{ padding: "10px 0" }} >
                    <h4 style={{ color: "#052977", marginLeft: "15px" }} >Settings</h4>
                    <Divider className='mb-2' />
                    <div style={{ padding: "5px 15px" }}>
                        {/* <Row>
                            <Col xs={12}>
                                <TextField
                                    size="small"
                                    onChange={e => { setter('shopper_cashback', Number(e.target.value)) }}
                                    value={value.shopper_cashback}
                                    id="outlined-basic"
                                    type='number'
                                    label="Shopper Cashback"
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row> */}
                        <Row>
                            <Col xs={12} className='text-left'>
                                <Typography className='fw-normal' color="primary" variant="h6" gutterBottom component="div">
                                    Creator/Influencer
                                </Typography>
                                <Divider className='mb-4' />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} className='text-left'>
                                <TextField
                                    size="small"
                                    onChange={e => { setter('referral_fee', Number(e.target.value)) }}
                                    value={value.referral_fee}
                                    id="outlined-basic"
                                    type='number'
                                    label="Referral Fee"
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <TextField
                                    size="small"
                                    onChange={e => { setter('content_creator_fee', Number(e.target.value)) }}
                                    value={value.content_creator_fee}
                                    id="outlined-basic"
                                    type='number'
                                    label="Creator Fee"
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col xs={12} className='text-left'>
                                <Typography className='fw-normal' color="primary" variant="h6" gutterBottom component="div">
                                    User
                                </Typography>
                                <Divider className='mb-4' />
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                <TextField
                                    size="small"
                                    onChange={e => { setter('normal_user_referral_fee', Number(e.target.value)) }}
                                    value={value.normal_user_referral_fee}
                                    id="outlined-basic"
                                    type='number'
                                    label="User Referral Fee"
                                    variant="outlined"
                                    className="field-style mb-15"
                                />
                            </Col>
                        </Row>
                    </div>
                    <Divider className='mb-3' />
                    <div style={{ display: "flex", margin: "5px 10px", justifyContent: "flex-end" }}>
                        <Button
                            onClick={close}
                            variant="outlined"
                            size="medium"
                            className="btn-gen-margin btn-width grey-btn-outline"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={call}
                            variant="contained"
                            size="medium"
                            className="btn-gen-margin btn-width"
                            color="primary"
                            disableElevation
                        >
                            Save
                        </Button>
                    </div>

                </Box>
            </Modal>
        </div >
    );
}