import React, { useState } from 'react'
import { Autocomplete, TextField } from '@mui/material';
import { connect } from 'react-redux'
import * as creatorActions from "../store/actions/creator"
import { useCallback } from 'react';
import { useEffect } from 'react';


export const SearchCreator = ({ getCreators, setter, brand, type, disable, editName, username, value }) => {
    const [nameLoad, setNameLoad] = useState(true);
    const [name, setName] = useState('')
    const [list, setList] = useState([])
    function debounce(cb, delay = 250) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }
    useEffect(() => {
        if (username === "") {
            setName('')
        } else {
            setName(username)
        }
    }, [username])
    const handleChange = (val) => {
        getCreators(val, false, 'creator', type, value).then((res) => {
            if (res?.success) {
                setList(res?.data?.message)
            }
            setNameLoad(false)
        })
    };
    const optimizedFn = useCallback(debounce(handleChange), []);

    useEffect(() => {
        if (brand === "") {
            setNameLoad(false)
            setName('')
            getCreators('', false, 'creator', type, value).then((res) => {
                if (res?.success) {
                    setList(res?.data?.message)
                }
                setNameLoad(false)
            })
        }
    }, [brand, value])

    return (
        <Autocomplete
            disabled={disable}
            variant="outlined"
            className="field-style"
            size="small"
            id="disable-clearable"
            disableClearable
            loading={nameLoad}
            options={nameLoad ? [] : list?.map(opt => ({ ...opt, label: `${opt.pixel_id} - ${opt.name}` }))}
            onChange={(e, data) => {
                setName(data.label)
                setter(data?._id, data)
            }}
            value={name}
            renderInput={(params) => <TextField {...params} label="User" onChange={(e) => {
                if (e.target.value !== "") {
                    setName(e.target.value)
                    if (editName) {
                        editName(e.target.value)
                    }
                    setNameLoad(true)
                    optimizedFn(e.target.value)
                } else {
                    setName(e.target.value)
                    if (editName) {
                        editName(e.target.value)
                    }
                    setNameLoad(true)
                    optimizedFn(e.target.value)
                    setter('')
                }
            }} />}
        />
    )
}

const mapStateToProps = ({ accountList }) => ({ accountList })

export default connect(mapStateToProps, creatorActions)(SearchCreator)