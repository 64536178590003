import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import ReportIcon from '@mui/icons-material/Report';
import SaveIcon from '@mui/icons-material/Save';
import SearchBrand from 'reUsable/SearchBrand';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
};

export default function BrandModal({ close, visible, title, call, type, flag, replaceBrandData, setReplaceBrandData, setBrandModal }) {


    const [load, setLoad] = useState(false)
    const [modal, setModal] = useState(false)
    const [passModal, setPassModal] = useState(false)
    const [fields, setFields] = useState({ brand_id: '' })
    const [data, setData] = useState({ brand_id: '', password: '' })

    function save() {
        setReplaceBrandData(fields)
        call()
    }
    return (
        <div>
            <Modal
                open={visible}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style} className="deleteModal w-30" >
                    <div className='mb-3'>
                        <SearchBrand setter={(value) => { setFields({ ...fields, brand_id: value }); setReplaceBrandData({ brand_id: value }) }} brand={fields.brand_id} />
                    </div>
                    <div className='d-flex justify-content-end'>
                        <Button
                            className="btn-width btn-gen-margin grey-btn-outline  laptop ml-0 mb-3"
                            variant="outlined"
                            sx={{ color: 'gray' }}
                            // className="btn-width btn-gen-margin ml-0 h-100"
                            disableElevation
                            size="medium"
                            onClick={() => {
                                setFields({ brand_id: '' })
                                setReplaceBrandData({ brand_id: '' })
                                setBrandModal(false)
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            disabled={load || fields.brand_id === ""}
                            className="btn-width btn-gen-margin ml-0 h-100 mb-3"
                            disableElevation
                            size="medium"
                            variant="contained"
                            onClick={save}
                        >
                            Replace
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}