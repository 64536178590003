import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { CardHeader, Box, InputLabel, MenuItem, FormControl, Select, CardActions, Button, Divider, Card, CardContent, CardMedia, Autocomplete, Typography, Avatar, Chip, CircularProgress } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import * as accountActions from "../../store/actions/account"
import * as contentActions from "../../store/actions/contentManage.action"
import SkeletonLoader from "reUsable/skeleton";
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import ReactPlayer from "react-player";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import ConfirmModal from "./Modal"
import BrandModal from "./BrandModal"
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    faAngleLeft,
    faAngleRight,
    faXmark,
    faCalendarDays,
    faClock,
    faCartFlatbed,
    faCalendar,
    faEye,
    faHeart,
    faShare,
} from "@fortawesome/free-solid-svg-icons";
import SearchBrand from "./BrandSerach";
import ProductModal from "./ProductModal";


function ManageReview({ getAccountList, accountList, reviews, getReviews, updateReview, replaceBrandDetail }) {
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true)
    const [fields, setFields] = useState({
        user_id: "",
        status: 'published',
        brand_id: "",
        sort_by: 'created_date',// views, shares, likes
        order_by: -1,
    })
    const [name, setName] = useState("")
    const [nameLoad, setNameLoad] = useState(false)

    const [modal, setModal] = useState(false)
    const [brandModal, setBrandModal] = useState(false)
    const [replaceBrandData, setReplaceBrandData] = useState()
    const [status, setStatus] = useState('')
    const [id, setID] = useState(null)
    const [load, setLoad] = useState(false);
    const [visible, setVisible] = useState(false)
    const [products, setProducts] = useState([])

    useEffect(() => { getData() }, [])
    useEffect(() => {
        setNameLoad(true)
        getAccountList('', false, fields.account_type).then(() => setNameLoad(false))
    }, [])
    const totalPage = useMemo(() => {
        if (reviews?.success) {
            return Math.ceil(reviews?.message?.total_records / limit)
        } else {
            return 1
        }
    }, [reviews])

    function onSearch() {
        setLoading(true)
        setPage(1)
        getData()
    }

    function clean() {
        setFields({
            user_id: "",
            status: "published",
            brand_id: '',
            sort_by: 'created_date',
            order_by: -1
        })
        setName('')
        setLoading(true)
        setPage(1)
        getReviews(limit, 1, false, {
            user_id: "",
            status: "published",
            brand_id: '',
            sort_by: 'created_date',
            order_by: -1
        }).then(() => setLoading(false))
    }



    function debounce(cb, delay = 500) {
        let timeout
        return (...args) => {
            clearTimeout(timeout)
            timeout = setTimeout(() => {
                cb(...args)
            }, delay)
        }
    }
    const handleChange = (val) => {
        getAccountList(val, false, fields.account_type).then(() => setNameLoad(false))
    };
    const optimizedFn = useCallback(debounce(handleChange), []);

    function fetchData() {
        if (page < totalPage) {
            setLoad(true)
            getReviews(limit, page + 1, true, fields).then(() => setLoad(false))
            setPage(page + 1)
        }
    }



    function renderClear() {
        return (
            <Col xs={12} md={3} xl={3} className="filter-clear-col mb-20">
                <Button
                    className="btn-width btn-gen-margin ml-0 h-100"
                    disableElevation
                    size="medium"
                    variant="contained"
                    startIcon={<SearchIcon />}
                    onClick={onSearch}
                >
                    Search
                </Button>
                <Button
                    className="btn-width btn-gen-margin grey-btn-outline h-100"
                    variant="outlined"
                    sx={{ color: 'gray' }}
                    startIcon={<ClearIcon />}
                    onClick={() => {
                        clean()
                    }}
                >
                    Clear
                </Button>
            </Col>
        )
    }
    function save() {
        let data = { _id: id }
        if (status === "Deleted") {
            data.is_deleted = true
        }
        if (status === "Published") {
            data.is_published = false
        }
        if (status === "Un-Published") {
            data.is_published = true
        }
        if (status === "Under-Review") {
            data.is_published = true
            data.under_review = false
        }
        setLoading(true)
        setModal(false)
        updateReview(data).then((res) => {
            if (res.success) {
                toast.success(res.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            } else {
                toast.error(res.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                });
            }
            getData()
        })
    }
    function replaceBrand() {

        let data = {
            review_id: id,
            brand_id: replaceBrandData?.brand_id
        }

        setLoading(true)
        setBrandModal(false)
        replaceBrandDetail(data).then((res) => {
            if (res.success) {
                toast.success(res.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                });
                setLoading(false)
                setReplaceBrandData()
            } else {
                toast.error(res.message, {
                    autoClose: 3000,
                    hideProgressBar: true,
                });
                setLoading(false)
                setReplaceBrandData()
            }
            getData()
        }).catch((err) => {
            setLoading(false)
            getData()
        })
    }

    const getData = () => {
        getReviews(limit, 1, false, fields).then(() => setLoading(false))
    }
    return (
        <React.Fragment>
            <ConfirmModal
                visible={modal}
                title={`Are you sure you want to save these changes?`}
                close={() => setModal(false)}
                type={status}
                call={() => save()}
            />
            <BrandModal
                visible={brandModal}
                // title={`Are you sure you want to save these changes?`}
                close={() => { setBrandModal(false); setReplaceBrandData() }}
                type={status}
                call={replaceBrand}
                replaceBrandData={replaceBrandData}
                setReplaceBrandData={setReplaceBrandData}
                setBrandModal={setBrandModal}
            />
            <ProductModal
                data={products}
                visible={visible}
                close={() => {
                    setProducts([])
                    setVisible(false)
                }}
            />
            <div className='container-fluid mt-3 mb-3 pl-0'>
                <Row className='pl-15'>
                    <Col xs className="mb-3 d-flex align-items-center">
                        <Typography className='title-bar-hd-main' color="primary" variant="h5" gutterBottom component="div">
                            Manage Reviews
                        </Typography>
                    </Col>
                </Row>
                <Row className="account-main-row pl-15">
                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <Autocomplete
                            variant="outlined"
                            className="field-style"
                            size="small"
                            id="disable-clearable"
                            disableClearable
                            loading={nameLoad}
                            options={accountList?.message ? accountList?.message.map(opt => ({ ...opt, label: `${opt.pixel_id} - ${opt.name}` })) : []}
                            onChange={(e, data) => {
                                setFields({ ...fields, user_id: data._id })
                                setName(data.label)
                            }}
                            value={name}
                            renderInput={(params) => <TextField {...params} label="Name" onChange={(e) => {
                                setName(e.target.value)
                                setNameLoad(true)
                                optimizedFn(e.target.value)
                            }} />}
                        />
                    </Col>
                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <SearchBrand setter={(value) => {
                                setFields({ ...fields, brand_id: value })
                            }} brand={fields.brand_id} />

                        </FormControl>
                    </Col>
                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Status</InputLabel>
                            <Select
                                sx={[{ color: "black" }]}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.status}
                                label="Status"
                                onChange={(e) => {
                                    setFields({ ...fields, status: e.target.value, })
                                }}
                            >
                                <MenuItem value={"all"}>All</MenuItem>
                                <MenuItem value={"published"}>Published</MenuItem>
                                <MenuItem value={"unpublished"}>Un-Published</MenuItem>
                                <MenuItem value={"underreview"}>Under Review</MenuItem>
                                <MenuItem value={"deleted"}>Deleted</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Sort By</InputLabel>
                            <Select
                                sx={[{ color: "black" }]}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.sort_by}
                                label="Sort By"
                                onChange={(e) => {
                                    setFields({ ...fields, sort_by: e.target.value, })
                                }}
                            >
                                <MenuItem value={"created_date"}>Date</MenuItem>
                                <MenuItem value={"likes"}>Likes</MenuItem>
                                <MenuItem value={"shares"}>Shares</MenuItem>
                                <MenuItem value={"views"}>Views</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    <Col xs={12} md={3} xl={3} className="mb-20">
                        <FormControl className="field-style" size="small">
                            <InputLabel
                                id="demo-simple-select-label"
                            >Order By</InputLabel>
                            <Select
                                sx={[{ color: "black" }]}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={fields.order_by}
                                label="Order By"
                                onChange={(e) => {
                                    setFields({ ...fields, order_by: e.target.value, })
                                }}
                            >
                                <MenuItem value={1}>Ascending</MenuItem>
                                <MenuItem value={-1}>Descending</MenuItem>
                            </Select>
                        </FormControl>
                    </Col>
                    {renderClear()}
                </Row>
                <Divider className="ml-15" />
                {loading ? (
                    <div className="pl-15" style={{ marginTop: 16 }}>
                        <SkeletonLoader />
                    </div>
                ) : (
                    <>
                        {
                            reviews?.message?.data?.length < 1 ? <div className="noReviews">No Reviews</div> :
                                <>
                                    <InfiniteScroll
                                        className="pl-15 mt-3 reviews-list-main"
                                        dataLength={reviews?.message?.data?.length} //This is important field to render the next data
                                        next={fetchData}
                                        hasMore={true}
                                    >
                                        {
                                            reviews?.message?.data?.map((item, i) => {
                                                let status = ''
                                                if (item?.is_active && !item?.is_deleted && !item?.under_review && item?.is_published) {
                                                    status = 'Published'
                                                } else if (item?.is_active && !item?.is_deleted && !item?.under_review && !item?.is_published) {
                                                    status = "Un-Published"
                                                } else if (item?.is_active && !item?.is_deleted && item?.under_review && !item?.is_published) {
                                                    status = "Under-Review"
                                                } else {
                                                    status = "Deleted"
                                                }
                                                return (
                                                    <>
                                                        <Card
                                                            variant="outlined"
                                                            className="reviews-list-box"
                                                        >
                                                            <CardHeader
                                                                sx={{ height: "55px", }}
                                                                avatar={<Avatar src={item?.influencer?.profile_image_url} sx={{ border: "1px solid #ebebeb" }} />}
                                                                title={

                                                                    <>
                                                                        <div className="title-area">
                                                                            <div className="title-name">
                                                                                {item?.influencer?.name}
                                                                            </div>
                                                                            <div className="pixel-id" >({item?.influencer?.pixel_id})</div>
                                                                        </div>
                                                                    </>




                                                                }
                                                            />
                                                            <div className="review-video">
                                                                <ReactPlayer
                                                                    playing={false}
                                                                    className={`review_react_player bg-black ${item?.media_url?.split(".")[item?.media_url?.split(".")?.length - 1] === "m3u8" && "objectFit"}`}
                                                                    width={"100%"}
                                                                    height={"250px"}
                                                                    url={item?.media_url}
                                                                    controls
                                                                    light={item?.banner}
                                                                    playsinline
                                                                />
                                                            </div>
                                                            <CardContent className="review-content">
                                                                <div className="mb-3" style={{ display: "flex", fontSize: "14px", fontWeight: "700", color: "#666666" }}>
                                                                    <div className="">
                                                                        <FontAwesomeIcon
                                                                            className="review-icon"
                                                                            icon={faEye}
                                                                        /> {item?.views}
                                                                    </div>
                                                                    <div className="ml-2"><FontAwesomeIcon
                                                                        className="review-icon"
                                                                        icon={faHeart}
                                                                    /> {item?.likes}</div>
                                                                    <div className="ml-2"><FontAwesomeIcon
                                                                        className="review-icon"
                                                                        icon={faShare}
                                                                    /> {item?.shares}</div>
                                                                </div>
                                                                <div className="review-date-main top-date">
                                                                    <div className="review-date">
                                                                        <FontAwesomeIcon
                                                                            className="review-icon"
                                                                            icon={faCalendar}
                                                                        />
                                                                        <div className="review-txt">
                                                                            {moment(item?.created_date)
                                                                                .format("MM-DD-YYYY")}
                                                                        </div>


                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            status === "Published" && <Chip label="Published" variant="filled" size="small" color="success" />
                                                                        }
                                                                        {
                                                                            status === "Un-Published" && <Chip label="Un Published" variant="filled" size="small" color="info" />
                                                                        }
                                                                        {
                                                                            status === "Under-Review" && <Chip label="Under Review" variant="filled" size="small" color="warning" />
                                                                        }
                                                                        {
                                                                            status === "Deleted" && <Chip label="Deleted" variant="filled" size="small" color="error" />
                                                                        }
                                                                    </div>

                                                                </div>

                                                                <h5 title={item?.title} className="btm-title">
                                                                    {item?.title}
                                                                </h5>

                                                                <Typography variant="body2" color="text.secondary">
                                                                    <div className="d-flex justify-content-between">
                                                                        {item?.brand && <p className="mt-2 mb-0 fw-bold"><span className="fw-bold">Brand: </span> {item?.brand?.brand_name} </p>}
                                                                        <Button
                                                                            onClick={() => {
                                                                                setID(item?._id)
                                                                                setBrandModal(true)
                                                                            }}
                                                                            variant="contained"
                                                                            size="small"
                                                                            color="primary"
                                                                            disableElevation
                                                                            disabled={item?.is_deleted}
                                                                        >
                                                                            Edit
                                                                        </Button>
                                                                    </div>

                                                                    {item?.review_counter && <p className="mt-2 mb-0"><span className="fw-bold">Review ID: </span> {item?.review_counter?.[0] + "-" + item?.review_counter?.split('R')[1].split('-').join('').match(/.{1,3}/g).join('-')} </p>}
                                                                </Typography>

                                                            </CardContent>
                                                            <Box sx={{ width: '100%' }}>
                                                                <CardActions className='reviews-box-buttons'>
                                                                    <Button
                                                                        onClick={() => {
                                                                            setProducts(item?.products?.map(m => ({ ...m, brand_name: item?.brand?.brand_name })))
                                                                            setVisible(true)
                                                                        }}

                                                                        variant="contained"
                                                                        size="small"
                                                                        color="primary"
                                                                        disableElevation
                                                                    >
                                                                        Products
                                                                    </Button>
                                                                    <Button
                                                                        onClick={() => {
                                                                            setID(item?._id)
                                                                            setStatus(status)
                                                                            setModal(true)
                                                                        }}
                                                                        variant="contained"
                                                                        size="small"
                                                                        color="primary"
                                                                        disableElevation
                                                                        disabled={item?.is_deleted}

                                                                    >
                                                                        {item?.is_published ? 'Un-Publish' : "Publish"}
                                                                    </Button>
                                                                    <Button
                                                                        style={{ marginLeft: 10 }}
                                                                        onClick={() => {
                                                                            setID(item?._id)
                                                                            setStatus('Deleted')
                                                                            setModal(true)
                                                                        }}
                                                                        variant="outlined"
                                                                        size="small"
                                                                        color="primary"
                                                                        disableElevation
                                                                        disabled={item?.is_deleted}
                                                                    >
                                                                        {item?.is_deleted ? "Deleted" : "Delete"}
                                                                    </Button>
                                                                </CardActions>
                                                            </Box>
                                                        </Card>
                                                    </>
                                                )
                                            })
                                        }
                                    </InfiniteScroll>
                                    {load && <div className="mt-2" style={{ textAlign: "center" }}><CircularProgress size={"20px"} /></div>}
                                </>
                        }
                    </>

                )}
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = ({ accountList, reviews }) => {
    return { accountList, reviews };
};

export default connect(mapStateToProps, { ...accountActions, ...contentActions })(ManageReview);
